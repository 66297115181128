import React from 'react';
import Feed, { FeedManual } from 'components/feed-management/Feed';

/**
 * Either ender the manual feed component or the regular feed component depending on the type of data set.
 */
class FeedWrapper extends React.Component {
    render() {
        const { feed = {}, dataSet, onGetDatasetItems = () => {} } = this.props;

        if (dataSet.customData && dataSet.customData.isManual) {
            return <FeedManual dataSet={dataSet} onGetDatasetItems={onGetDatasetItems} />;
        }

        if (!(dataSet.customData && dataSet.customData.isManual)) {
            return <Feed feed={feed} dataSet={dataSet} onGetDatasetItems={onGetDatasetItems} />;
        }
    }
}

export default FeedWrapper;
