import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';
import DataSetManagerInCampaign from './components/in-campaign.js';
import DataSetManager from './components/index.js';
import DataSetManagerTabbar from './components/tabbar.tsx';

const DataSetManagerDynamicData = EditorDynamicDataWrapper(DataSetManager);
const DataSetManagerInCampaignDynamicData = EditorDynamicDataWrapper(DataSetManagerInCampaign);

export default DataSetManagerDynamicData;
export { DataSetManager, DataSetManagerInCampaign, DataSetManagerDynamicData, DataSetManagerInCampaignDynamicData, DataSetManagerTabbar };
