import React from 'react';
import EditorData from 'components/editor-data/EditorData';
import Loader from 'components/ui-components/Loader';
import AlertBox from 'components/ui-components/AlertBox';
import ComponentStore from 'components/data/ComponentStore';
import { withComponentStore } from 'components/data/ComponentStore';

/**
 * Wrapper for the data set manager.
 * Make sure the right data is loaded and passed when the data set manager is used in the campaign editor.
 */
class DataSetManagerInCampaignLoader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSetIdNotFound: false
        };
    }

    componentDidMount = () => {
        const { dataSetLoadCompleted } = this.props;
        if (dataSetLoadCompleted) {
            this.setDataSet();
        }
    };

    componentDidUpdate = (prevProps) => {
        const { dataSetLoadCompleted } = this.props;
        if (!prevProps.dataSetLoadCompleted && dataSetLoadCompleted) {
            this.setDataSet();
        }
    };

    // Clear the loaded data set when leaving the component.
    componentWillUnmount = () => {
        ComponentStore.setData('FeedManager', { dataSet: null });
    };

    // Make sure the right dataset is set in Redux.
    // In the FeedManager the controller takes care of that.
    setDataSet = () => {
        const { datasetId, dataSetList } = this.props;
        let dataSet = null;
        let match;
        if (dataSetList && dataSetList.length > 0) {
            if (datasetId) {
                match = dataSetList.find((item) => item._id === datasetId);
                if (!match) this.setState({ dataSetIdNotFound: true });
            } else {
                const campaignId = EditorData.get('id');
                match = dataSetList.find((item) => item.autoPublish && item.autoPublish.campaignId === Number(campaignId));
            }
            if (match) dataSet = match;
        }
        ComponentStore.setData('FeedManager', { dataSet });
    };

    render() {
        const { dataSetIdNotFound } = this.state;
        const { children, dataSetLoadCompleted } = this.props;

        if (dataSetIdNotFound)
            return (
                <AlertBox type="error" hideClose>
                    The dataset id, as specified in the campaign format, does not exist.
                </AlertBox>
            );

        return <Loader isLoading={!dataSetLoadCompleted}>{children && children}</Loader>;
    }
}

export default withComponentStore(DataSetManagerInCampaignLoader, 'FeedManager', []);
