import '../styles/main.scss';
import React, { Component } from 'react';
import FormFlow from 'components/ui-base/FormFlow';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';

/** empty feed */
const newData = {
    enabled: true,
    schedule: '*/30 * * * *'
};

const formSetup = [
    {
        items: [
            {
                type: 'switch',
                model: 'enabled',
                title: 'Autopublish enabled',
                condition: `data.schedule !== "${FeedHelpers.neverCron}"`
            },
            {
                type: 'radioGroup',
                model: 'schedule',
                title: 'Select autopublish schedule',
                options: FeedHelpers.feedCrons,
                condition: 'data.enabled'
            }
        ]
    }
];

/**
 * Dialog to add a new manual data set.
 */
export default class DialogManualDataset extends Component {
    /**
     * Add a new manual data set
     * @param {object} formData
     */
    addManualDataSet = (formData) => {
        const { dataSetStructure, onClose } = this.props;

        const campaignId = EditorData.get('id');
        const campaignTitle = EditorData.get('title');
        const enabled = formData.schedule === FeedHelpers.neverCron ? false : formData.enabled;

        const customData = {
            title: `${campaignTitle} - ${campaignId}`,
            isManual: true
        };
        if (Array.isArray(dataSetStructure) && dataSetStructure.length > 0) {
            customData.dataSetStructure = dataSetStructure;
        }

        const datasetPostData = {
            autoPublish: {
                campaignId: Number(campaignId),
                schedule: formData.schedule,
                enabled,
                runAfterPoll: false
            },
            customData,
            structure: {},
            feeds: []
        };

        return Promise.resolve(
            FeedRequest.post('dataset', datasetPostData)
                .then((data) => {
                    FeedHelpers.setActiveDataSet(data.data);
                    onClose();
                })
                .catch((error) => {
                    SnackbarUtils.error(
                        Translation.get('dataSet.manualDataSetDialog.failedToAddDataSet', 'feed-management')
                    );
                    console.log('Failed to  manual dataset', error);
                })
        );
    };

    render() {
        const { open, onClose, data = {} } = this.props;

        return (
            <div className="data-set-manager__manual-dataset">
                <FormFlow
                    onSubmit={this.addManualDataSet}
                    setup={formSetup}
                    data={Object.keys(data).length > 0 ? data : newData}
                    submitButtonLabel={Translation.get('feeds.createManualDataSet', 'feed-management')}
                    view="modal"
                    onCloseDialog={onClose}
                    title={Translation.get('feeds.createManualDataSet', 'feed-management')}
                    modalOpen={open}
                    saveNotification={Translation.get('feeds.createdManualDataSet', 'feed-management')}
                />
            </div>
        );
    }
}
