import React, { useState } from 'react';
import slugify from 'slugify';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Menu from 'components/ui-components-v2/Menu';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import User from 'components/data/User';
import Button from 'components/ui-components-v2/Button';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import ComponentStore from 'components/data/ComponentStore';
import { withComponentStore } from 'components/data/ComponentStore';

import '../styles/export.scss';
import Translation from 'components/data/Translation';

/**
 * Display the export button and handle exports.
 * @param {*} param0
 * @returns
 */
const DataSetManagerExport = ({ dataSet = {}, hasContent, exportTasks = [] }) => {
    const [anchorEl, setAnchorEl] = useState(false);

    /**
     * Starts the requested download and store the operationId in Redux.
     * @param {string} type download type / extention.
     */
    const startExport = (type) => {
        setAnchorEl(false);
        FeedRequest.get(`dataset/${dataSet._id}/export/${type}`)
            .then((data) => {
                SnackbarUtils.info(Translation.get('dataSet.export.waitUntilReady', 'feed-management'));
                if (data.data && data.data.operationId) {
                    const operationId = data.data.operationId;
                    const fileName = dataSet.customData && dataSet.customData.title ? `${slugify(dataSet.customData.title)}.${type}` : `${dataSet._id}.${type}`;
                    exportTasks.push({ operationId, fileName });
                    ComponentStore.setData('FeedManager', { exportTasks });
                }
            })
            .catch((error) => {
                console.error('Export failed', error);
            });
    };

    if (User.hasRight('feedExport') && dataSet && !!dataSet._id && hasContent) {
        return (
            <div className="data-set-manager-export">
                {exportTasks && exportTasks.length > 0 && <CircularProgress size={32} classes={{ root: 'data-set-manager-export__spinner' }} />}
                <Button
                    variant="text"
                    color="primary"
                    endIcon={<ArrowDropDownIcon />}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    disabled={exportTasks && exportTasks.length > 0}>
                    {Translation.get('dataSet.export.export', 'feed-management')}
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    <MenuItem onClick={() => startExport('csv')}>{Translation.get('dataSet.export.exportCSV', 'feed-management')}</MenuItem>
                    <MenuItem onClick={() => startExport('json')}>{Translation.get('dataSet.export.exportJSON', 'feed-management')}</MenuItem>
                    <MenuItem onClick={() => startExport('xml')}>{Translation.get('dataSet.export.exportXML', 'feed-management')}</MenuItem>
                    <MenuItem onClick={() => startExport('xlsx')}>{Translation.get('dataSet.export.exportXLSX', 'feed-management')}</MenuItem>
                </Menu>
            </div>
        );
    }
    return null;
};

export default withComponentStore(DataSetManagerExport, 'FeedManager', []);
