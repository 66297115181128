import '../styles/main.scss';
import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import TextField from 'components/ui-components-v2/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Dialog from 'components/ui-components-v2/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from 'components/ui-components-v2/Button';
import DataHelpers from 'components/data/DataHelpers';
import EditorData from 'components/editor-data/EditorData';
import CreativeBuilderBlock from 'components/creative-builder/CreativeBuilderBlock';
import User from 'components/data/User';
import cloneDeep from 'helpers/cloneDeep';
import Request from 'components/data/Request';
import RouteHelper from 'components/ui-base/RouteHelper';

/**
 * Social review function
 * Custom component for reviewing social assets by subaccounts.
 */
class SocialCustomizerReview extends Component {
    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            status: EditorData.getValueFromModel('review.account_' + User.get('subAccountId') + '.status'),
            step: 0,
            itemIndex: 0,
            item: {},
            title: EditorData.getValueFromModel('settings.customizer.title.value'),
            introduction: EditorData.getValueFromModel('settings.customizer.introduction'),
            success: EditorData.getValueFromModel('settings.customizer.success'),
            ended: EditorData.getValueFromModel('settings.customizer.ended'),
            endDate: EditorData.getValueFromModel('settings.customizer.endDate'),
            image: EditorData.getValueFromModel('settings.customizer.image.url')
        };

        // Check if campaign is ended
        if (this.state.endDate) {
            if (moment().isAfter(moment(this.state.endDate))) {
                this.state.step = 3;
            }
        }

        EditorData.setModel('tempData', cloneDeep(props.dataSet));
    }

    handleSelectBlock = (uuid) => {
        const { itemList } = this.state;
        const selectedItem = itemList.find((x) => x.uuid === uuid);
        this.setState({ item: selectedItem });
    };

    handleStartClick = () => {
        this.openItem(0);
    };

    handleApproveAll = () => {
        this.saveCampaignDetail({ status: 'approveAll' });
        this.setDone();
    };

    handleDeclineAll = () => {
        this.saveCampaignDetail({ status: 'declineAll' });
        this.setDone();
    };

    handleStatus = (status) => {
        const { list } = this.props;
        const { itemIndex } = this.state;
        const item = list[itemIndex];

        this.saveCampaignDetail({
            status: 'custom',
            ['item_' + item.uuid]: {
                status: status,
                message: this.state.message,
                messageUpdated: this.state.messageUpdated
            }
        });
        if (list.length - 1 === itemIndex) {
            this.setDone();
        } else {
            this.openItem(itemIndex + 1);
        }
    };

    openItem = (itemIndex) => {
        const { list } = this.props;
        const item = list[itemIndex];
        const data = EditorData.getValueFromModel('tempData.' + item.uuid);
        let message = DataHelpers.getValue(data, 'data.main.channelData.message.' + EditorData.get('language') + '.value');

        // Overwritten message
        let messageUpdated = false;
        const customMessage = EditorData.getValueFromModel('review.account_' + User.get('subAccountId') + '.item_' + item.uuid + '.message');
        if (customMessage && customMessage !== message) {
            message = customMessage;
            messageUpdated = true;
            EditorData.setModel('tempData.' + item.uuid + '.data.main.channelData.message.' + EditorData.get('language') + '.value', customMessage);
        }

        let online = EditorData.getValueFromModel('settings.planning.online');
        let offline = EditorData.getValueFromModel('settings.planning.offline');

        if (DataHelpers.getValue(data, 'publishing.startDate')) {
            online = DataHelpers.getValue(data, 'publishing.startDate');
        }
        if (DataHelpers.getValue(data, 'publishing.endDate')) {
            offline = DataHelpers.getValue(data, 'publishing.endDate');
        }

        this.custom = false;

        this.setState({
            item,
            data,
            message,
            step: 1,
            itemIndex,
            itemOnline: online,
            itemOffline: offline,
            messageUpdated: messageUpdated
        });
    };

    saveCampaignDetail = (obj, save = true) => {
        let reviewData = EditorData.getValueFromModel('review.account_' + User.get('subAccountId'));
        if (!reviewData) {
            reviewData = {};
        }
        reviewData = { ...reviewData, subAccountId: User.get('subAccountId'), ...obj, editTime: moment().format('YYYY-MM-DD HH:mm:SS') };
        EditorData.setModel('review.account_' + User.get('subAccountId'), reviewData);

        // Save to campaign
        if (save) {
            Request.post('/campaignsReview/save', {
                id: EditorData.get('id'),
                type: 'subAccount',
                data: reviewData
            });
        }
    };

    setDone = () => {
        this.saveCampaignDetail({ done: true });
        this.setState({ step: 2 });
    };

    handleMessageChange = (e) => {
        const { item, dataSet } = this.state;
        const newMessage = e.target.value;
        EditorData.setModel('tempData.' + item.uuid + '.data.main.channelData.message.' + EditorData.get('language') + '.value', newMessage);
        this.custom = true;

        this.setState({ message: newMessage, dataSet });
        this.saveCampaignDetail(
            {
                customized: true,
                ['item_' + item.uuid]: {
                    status: 'custom',
                    message: newMessage,
                    messageUpdated: true
                }
            },
            false
        );
    };

    handlePrevious = () => {
        const { itemIndex } = this.state;
        this.openItem(itemIndex - 1);
    };

    handleClose = () => {
        this.setState({ step: 0 });
    };

    handleViewAll = () => {
        this.setState({ viewAll: true });
    };

    hideViewAll = () => {
        this.setState({ viewAll: false });
    };

    handleBack = () => {
        RouteHelper.openLink('/');
    };

    /**
     * Render
     */
    render() {
        const { step, status, title, introduction, success, ended, image, message, item, data, itemIndex, viewAll, itemOnline, itemOffline } = this.state;
        const { list, dataSet } = this.props;

        // Introduction
        if (step === 0 || step === 1) {
            return (
                <div>
                    <div className="social-customizer__review">
                        <div className="social-customizer__introduction">
                            <div className="social-customizer__introduction__container">
                                <div className="social-customizer__introduction__image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
                                <div className="social-customizer__introduction__text">
                                    <h1>{title}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(introduction) }}></div>
                                    <Button variant="outlined" onClick={this.handleViewAll}>
                                        View all
                                    </Button>
                                </div>

                                {status && (
                                    <div className="social-customizer__introduction__status">
                                        <div className="social-customizer__introduction__status__current">Current status: </div>
                                        <div
                                            className={
                                                'social-customizer__introduction__status__value social-customizer__introduction__status__value--' + status
                                            }>
                                            {status === 'approveAll' && 'All approved'}
                                            {status === 'declineAll' && 'All declined'}
                                            {status === 'custom' && 'Customized'}
                                        </div>
                                    </div>
                                )}

                                <div className="social-customizer__introduction__buttons">
                                    <div className="social-customizer__introduction__buttons__groupa">
                                        <Button className="social-customizer__introduction__button" color="error" onClick={this.handleDeclineAll}>
                                            Decline all
                                        </Button>
                                    </div>
                                    <div className="social-customizer__introduction__buttons__groupb">
                                        <Button variant="outlined" onClick={this.handleStartClick} className="social-customizer__introduction__button">
                                            View & Customize
                                        </Button>
                                        <Button variant="contained" onClick={this.handleApproveAll}>
                                            Approve all
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {viewAll && (
                        <Dialog maxWidth={'lg'} open={true} onClose={this.hideViewAll}>
                            <DialogContent>
                                <div style={{ width: '740px' }} className="social-customizer__overview-all">
                                    {list.map((dataItem, index) => (
                                        <div key={index} className="social-customizer__overview-all__item">
                                            <CreativeBuilderBlock
                                                hideControls={true}
                                                key={dataItem.uuid}
                                                item={dataItem}
                                                data={dataSet[dataItem.uuid]}
                                                dataModel={'tempData.' + dataItem.uuid}
                                                language={EditorData.get('language')}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </DialogContent>
                        </Dialog>
                    )}
                    {step === 1 && (
                        <Dialog maxWidth={'lg'} open={true} onClose={this.handleClose}>
                            <DialogContent>
                                <div className="social-customizer__review">
                                    <div className="social-customizer__review-item">
                                        <div className="social-customizer__review-item__post">
                                            <div className="social-customizer__review-item__post__date">
                                                {moment(itemOnline).format('DD MMM YYYY')} - {moment(itemOffline).format('DD MMM YYYY')}
                                            </div>
                                            <CreativeBuilderBlock
                                                hideControls={true}
                                                key={item.uuid}
                                                item={item}
                                                data={data}
                                                dataModel={'tempData.' + item.uuid}
                                                language={EditorData.get('language')}
                                            />
                                        </div>
                                        <div className="social-customizer__review-item__side">
                                            <h3>Do you want to accept this post?</h3>
                                            <div>Please update the copy below or approve the existing copy.</div>

                                            <div className="social-customizer__review-item__side__customize">
                                                <TextField
                                                    margin="dense"
                                                    multiline
                                                    rows={8}
                                                    value={message}
                                                    InputProps={{
                                                        style: { color: '#081243', fontSize: '1em' }
                                                    }}
                                                    fullWidth
                                                    onChange={this.handleMessageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions style={{ borderTop: '1px solid #EEE' }}>
                                {itemIndex > 0 && (
                                    <IconButton aria-label="fingerprint" color="secondary" onClick={this.handlePrevious}>
                                        <ArrowBack />
                                    </IconButton>
                                )}

                                <Button className="social-customizer__introduction__button" color="error" onClick={() => this.handleStatus('decline')}>
                                    Decline
                                </Button>
                                <Button
                                    className="social-customizer__introduction__button"
                                    color="success"
                                    variant="contained"
                                    onClick={() => this.handleStatus('approve')}>
                                    Approve
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </div>
            );
        }
        // Completed
        else if (step === 2) {
            return (
                <div className="social-customizer__review">
                    <div className="social-customizer__introduction">
                        <div className="social-customizer__introduction__container">
                            <div className="social-customizer__introduction__image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
                            <div className="social-customizer__introduction__text">
                                <h2>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(success) }}></div>
                                </h2>

                                <Button variant="outlined" onClick={this.handleBack}>
                                    Go back
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="social-customizer__review-item__bottom-bar">
                        <IconButton aria-label="fingerprint" color="secondary" onClick={this.handleStartClick}>
                            <ArrowBack />
                        </IconButton>
                    </div>
                </div>
            );
        } else if (step === 3) {
            return (
                <div className="social-customizer__review">
                    <div className="social-customizer__introduction">
                        <div className="social-customizer__introduction__container">
                            <div className="social-customizer__introduction__image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
                            <div className="social-customizer__introduction__text">
                                <h2>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ended) }}></div>
                                </h2>
                                <Button variant="outlined" onClick={this.handleViewAll}>
                                    View all
                                </Button>
                                <Button variant="contained" type="contained" style={{ marginLeft: 16 }} onClick={this.handleBack}>
                                    Go back
                                </Button>
                            </div>
                        </div>
                    </div>

                    {viewAll && (
                        <Dialog maxWidth={'lg'} open={true} onClose={this.hideViewAll}>
                            <DialogContent>
                                <div style={{ width: '740px' }} className="social-customizer__overview-all">
                                    {list.map((dataItem, index) => (
                                        <div key={index} className="social-customizer__overview-all__item">
                                            <CreativeBuilderBlock
                                                hideControls={true}
                                                key={dataItem.uuid}
                                                item={dataItem}
                                                data={dataSet[dataItem.uuid]}
                                                dataModel={'tempData.' + dataItem.uuid}
                                                language={EditorData.get('language')}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </DialogContent>
                        </Dialog>
                    )}
                </div>
            );
        }
    }
}

export default SocialCustomizerReview;
