import React from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Illustration from 'components/ui-components-cape/Illustration';

import '../styles/empty.scss';

/**
 * Display an empty state in the data set manager.
 * @param {*} param0
 * @returns
 */
const DataSetManagerEmpty = ({ spinner, placeholder, title, subtitle, children }) => {
    return (
        <div className="data-set-manager-empty">
            {placeholder && <Illustration size="large" />}
            {spinner && (
                <div className="data-set-manager-empty__spinnerholder">
                    <CircularProgress
                        size={60}
                        classes={{
                            circle: 'data-set-manager-empty__spinnerholder__spinner'
                        }}
                    />
                </div>
            )}
            {title && <div className="data-set-manager-empty__title">{title}</div>}
            {subtitle && <div className="data-set-manager-empty__subtitle">{subtitle}</div>}
            {children && <div className="data-set-manager-empty__actions">{children}</div>}
        </div>
    );
};

export default DataSetManagerEmpty;
