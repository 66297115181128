import React from 'react';
import EditorData from 'components/editor-data/EditorData';
import SubBlock from './sub-block';
import Inputs from './inputs.js';

/**
 * The MultiInput blocks is the component that is mostly used by the interface.
 * It displays a block with different input fields inside, and has several options for sidebars etc.
 * Please check Nuclino for more extensive docks.
 */
class MultiInput extends React.Component {
    /**
     * RenderElement
     * Renders an individual item in the multi input block
     * @param {object} item item to be added
     * @param {int} i The index of the item in the list
     * @param {string} blockModel The blockmodel to be used in the list if available
     * @param {array} linkedBlockModels An array of all the linked block models
     */
    renderElement = (item, i, blockModel = undefined, linkedBlockModels = undefined) => {
        const { localScope } = this.props;
        const dynamicValueActive = this.props.dynamicValueActive || this.props.data.dynamicValueActive || false;

        // Get block model if available
        let blockData = {};
        let blockDataParent = {};

        // Get the blockmodel. This can be set as a prop or in the item itself.
        if (item.blockModel) {
            blockModel = item.blockModel;
        } else if (this.props.blockModel) {
            blockModel = this.props.blockModel;
        } else if (this.props.data.blockModel) {
            blockModel = this.props.data.blockModel;
        }

        // Fetch data in case there is a condition added to the block. This data is needed to validate the condition
        if (item && item.condition) {
            if (blockModel) {
                blockData = EditorData.getValueFromModel(blockModel, null, localScope);
            }

            // Get block model parent if available
            if (item.blockModelParent) {
                blockDataParent = EditorData.getValueFromModel(item.blockModelParent, null, localScope);
            }
        }

        // Get additional vars if available
        let additionalVars = {};
        if (this.props.data?.additionalVars) {
            additionalVars = this.props.data?.additionalVars;
            item.additionalVars = additionalVars;
        }

        // Get sourcedatamodel if available
        let sourceDataBlockModel;
        if (this.props.sourceDataBlockModel) {
            sourceDataBlockModel = this.props.sourceDataBlockModel;
        } else if (this.props.data.sourceDataBlockModel) {
            sourceDataBlockModel = this.props.data.sourceDataBlockModel;
        }

        // Get blockmodel data
        if (item.linkedBlockModels) {
            linkedBlockModels = item.linkedBlockModels;
        } else if (!linkedBlockModels) {
            linkedBlockModels = [];
            if (this.props.linkedBlockModels) {
                linkedBlockModels = this.props.linkedBlockModels;
            }
        }

        const componentProps = {
            data: item,
            key: 'block_' + item.type + '_' + i,
            localScope: localScope,
            dynamicValueActive
        };

        const modelProps = {
            blockModel,
            sourceDataBlockModel,
            linkedBlockModels,
            additionalVars
        };

        const TagName = Inputs[item.type];

        // Check conditional fields to see whether we can show this element.
        if (!EditorData.validateCondition(item, blockData, blockDataParent, additionalVars)) {
            return <React.Fragment key={componentProps.key}></React.Fragment>;
        }
        // Block related elements
        else if (item.type === 'subSection') {
            return this.renderSubSection(item, i);
        } else if (item.type === 'subTitle') {
            return (
                <div className="section-sub-title" key={componentProps.key}>
                    {item.title}
                </div>
            );
        }
        // Input fields with additional props
        else if (item.type === 'textMultiLanguage' || item.type === 'itemList') {
            return <TagName {...componentProps} {...modelProps} additionalProps={{ language: EditorData.getLanguage() }} />;
        }

        /* Customer specific */

        // #if process.env.customer === 'klm' || process.env.customer === 'afkl' || process.env.customer === 'airfrance'
//         else if (item.type === 'KLMOriginSelector') {
//             return <TagName {...componentProps} />;
//         } else if (item.type === 'KLMPrimaryOriginSelector') {
//             const newItem = { ...item, origin: '[[[campaign]]].settings.origins' };
//             return <TagName data={newItem} {...componentProps} />;
//         } else if (item.type === 'KLMDestinationSelector') {
//             // Fix for adding dynamic origin in there
//             const newItem = { ...item, origin: '[[[editor]]].origin' };
//             return <TagName key={'block_' + item.type + '_' + i} data={newItem} {...modelProps} />;
//         } else if (item.type === 'KLMOriginSwitch') {
//             // Fix for adding dynamic origin in there
//             const newItem = { ...item, origin: '[[[editor]]].origin' };
//             return <TagName key={'block_' + item.type + '_' + i} data={newItem} {...modelProps} />;
//         } else if (item.type === 'KLMB2BDisplaySelector') {
//             return <TagName {...componentProps} {...modelProps} />;
//         }
        // #endif

        // Standard
        else {
            // Generate the correct tagname
            if (!Inputs[item.type]) {
                console.log('Field not found ', item.type);
                return <React.Fragment key={componentProps.key} />;
            }

            return <TagName {...componentProps} {...modelProps} />;
        }
    };

    /**
     * Render subsection
     * Renders an individual subblock with all items
     * @param {object} block The block to be rendered
     * @param {int} index The index of the current subsection
     */
    renderSubSection = (block, index) => {
        return (
            <SubBlock
                title={block.title}
                opened={block.opened}
                inputKey={block.inputKey}
                key={'subsection' + index}
                tooltip={block.tooltip}
                helperText={block.helperText}>
                {block.items &&
                    block.items.map((item, ib) =>
                        this.renderElement(
                            item,
                            index + '_' + ib,
                            block.blockModel ? block.blockModel : undefined,
                            block.linkedBlockModels ? block.linkedBlockModels : undefined
                        )
                    )}
            </SubBlock>
        );
    };

    render() {
        const { data = { items: [] } } = this.props;
        return <div className="editor-block__multi-input">{data.items && data.items.map((item, i) => this.renderElement(item, i))}</div>;
    }
}

export default MultiInput;
