import '../styles/main.scss';
import React, { Component } from 'react';
import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import CampaignStatusChip from 'components/ui-components/CampaignStatusChip';
import Request from 'components/data/Request';

class CampaignStatus extends Component {
    static defaultProps = {};

    constructor(props) {
        super(props);
        // Set initial state
        this.state = {
            status: EditorData.get('status')
        };
    }

    /**
     * Change campaign status and send to the server
     */
    onChangeItem = (newStatus) => {
        this.setState({ status: newStatus });
        Request.post('editor/updateStatus', { id: EditorData.getId(), status: newStatus }).then(() => {
            SnackbarUtils.success('The campaign status was updated');
        });
    };

    /**
     * Render
     */
    render() {
        const { status } = this.state;
        const { data } = this.props;

        return (
            <div className="campaign-status">
                <div className="campaign-status__title">Change campaign status to:</div>

                <div className="campaign-status__options">
                    {data.options &&
                        data.options.map((statusOption, i) => (
                            <CampaignStatusChip
                                large={true}
                                status={statusOption}
                                key={i}
                                selected={status == statusOption ? true : false}
                                onClick={() => this.onChangeItem(statusOption)}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default CampaignStatus;
