import React from 'react';
import Outputs from 'controllers/FeedManagement/components/outputs';
import Settings from 'controllers/FeedManagement/components/settings';
import User from 'components/data/User';
import FeedHelpers, { FeedSubscription } from 'components/data/FeedManagementHelpers';
import DataSetManagerInCampaignLoader from './in-campaign-loader';
import FeedManagementActivity from '../../../feed-management/Activity/components';
import DataSetManagerTabbar from './tabbar';
import DataSetManager from './';

import '../styles/in-campaign.scss';

/**
 * so the DataSetManger can be used stand-alone in the campaign editor.
 */
class DataSetManagerInCampaign extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subscription: null,
            activeTab: 'feed',
            displayType: 'list',
            searchTerm: '',
            hasContent: false,
            kickManualRecordDialog: false
        };
    }

    componentDidMount = () => {
        if (User.hasRight('feedManagement')) {
            FeedHelpers.loadData();
            this.setState({ ...this.state, subscription: FeedSubscription.subscribe() });
        }
    };

    componentWillUnmount = () => {
        if (User.hasRight('feedManagement')) {
            const { subscription } = this.state;
            if (subscription) FeedSubscription.unsubscribe(subscription);
        }
    };

    render() {
        const { activeTab, displayType, searchTerm, hasContent, kickManualRecordDialog } = this.state;
        const { ...props } = this.props;
        if (!User.hasRight('feedManagement')) return null;

        return (
            <DataSetManagerInCampaignLoader {...props}>
                <DataSetManagerTabbar
                    {...props}
                    activeTab={activeTab}
                    onClickTab={(item) => this.setState({ activeTab: item.key })}
                    onAfterRemove={() => this.setState({ activeTab: 'feed' })}
                    onSetDisplayType={(displayType) => this.setState({ displayType })}
                    onChangeSearchTerm={(searchTerm) => this.setState({ searchTerm })}
                    onKickManualRecordDialog={(value) => this.setState({ kickManualRecordDialog: value })}
                    displayType={displayType}
                    searchTerm={searchTerm}
                    hasContent={true}
                    className="data-set-manager-in-campaign__tabbar"
                />
                {activeTab === 'feed' && (
                    <DataSetManager
                        {...props}
                        displayType={displayType}
                        searchTerm={searchTerm}
                        kickManualRecordDialog={kickManualRecordDialog}
                        onKickManualRecordDialog={(value) => this.setState({ kickManualRecordDialog: value })}
                        onSetHasContent={(hasContent) => this.setState({ hasContent })}
                    />
                )}
                {activeTab === 'activity' && <FeedManagementActivity />}
                {activeTab === 'outputs' && <Outputs />}
                {activeTab === 'settings' && <Settings inCampaign onSetTab={(activeTab) => this.setState({ activeTab })} />}
            </DataSetManagerInCampaignLoader>
        );
    }
}

export default DataSetManagerInCampaign;
