import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import '../styles/sub-block.scss';

/**
 * This is a subblock in the MultiInput.
 * This displays a dropdown that we can open and close. Within the dropdown, the children are displayed.
 */
class SubBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: props.opened ? true : false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.opened !== this.props.opened) {
            this.setState({ opened: this.props.opened });
        }
    }

    /**
     * Toggle the subblock open and closeds
     */
    onToggle = () => {
        this.setState({ opened: this.state.opened ? false : true });
    };

    render() {
        const { title, children, inputKey, tooltip, helperText } = this.props;
        const { opened } = this.state;

        // Render subblock
        return (
            <div className="editor-block-sub" data-tour-selector={'subSection-' + title}>
                <div className="editor-block-sub__title-bar" onClick={this.onToggle} data-input-key={inputKey}>
                    <div className="editor-block-sub__title-bar__title">{title}</div>
                    {tooltip && (
                        <div className="editor-block-sub__title-bar__tooltip">
                            <Tooltip title={tooltip}>
                                <Icon className="editor-block-sub__title-bar__tooltip-icon">info</Icon>
                            </Tooltip>
                        </div>
                    )}
                    <div className="editor-block-sub__title-bar__arrow">
                        {!opened && <Icon fontSize="small">expand_more</Icon>}
                        {opened && <Icon fontSize="small">expand_less</Icon>}
                    </div>
                </div>
                {helperText && <div className="editor-block-sub__helper-text">{helperText}</div>}
                {opened && <div className="editor-block-sub__content">{children}</div>}
            </div>
        );
    }
}

export default SubBlock;
