import React, { useState, useEffect } from 'react';
import DataSetType from 'types/dataset.type';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import FeedManagerDataSetRemoveForm from 'components/feed-management/DataSetRemoveForm';
import OutputForm from 'components/feed-management/OutputForm';
import { Toolbar, ToolbarGroup } from 'components/ui-base/Toolbar';
import Tabbar, { Tab, TabbarSearch } from 'components/ui-base/Tabbar';
import ViewSwitch from 'components/ui-components/ViewSwitch';
import { withComponentStore } from 'components/data/ComponentStore';
import DataSetManagerExport from './export';

import '../styles/tabbar.scss';

interface Props {
    activeTab?: string;
    id: string;
    className?: string;
    displayType: DisplayType;
    hideGridView?: boolean;
    searchTerm?: string;
    dataSet: DataSetType;
    hasContent: boolean;
    onClickTab: (item: Tab) => void;
    onAfterRemove: () => void;
    onSetDisplayType: (type: DisplayType) => void;
    onChangeSearchTerm: (searchTerm: string) => void;
    onKickManualRecordDialog: (value: boolean) => void;
}

type DisplayType = 'list' | 'grid';

const getTabs = (dataSet: DataSetType, id: string, activeTab?: string) => {
    const tabs: Tab[] = [];
    const manual = dataSet?.customData?.isManual;
    tabs.push({
        key: 'feed',
        path: '/feed-management/dataset/' + id,
        title: Translation.get('editor.tabFeedTitle', 'feed-management'),
        active: !activeTab || activeTab === '' || activeTab === 'feed'
    });
    if (!manual) {
        tabs.push({
            key: 'activity',
            path: '/feed-management/dataset/' + id + '/activity',
            title: Translation.get('editor.tabActivityTitle', 'feed-management'),
            active: activeTab === 'activity'
        });
    }
    tabs.push({
        key: 'outputs',
        path: '/feed-management/dataset/' + id + '/outputs',
        title: Translation.get('editor.tabOutputsTitle', 'feed-management'),
        active: activeTab === 'outputs'
    });
    tabs.push({
        key: 'settings',
        path: '/feed-management/dataset/' + id + '/settings',
        title: Translation.get('editor.tabSettingsTitle', 'feed-management'),
        active: activeTab === 'settings'
    });

    return tabs;
};

const DataSetManagerTabbar = ({
    activeTab,
    id,
    dataSet,
    className,
    displayType,
    hideGridView,
    hasContent,
    searchTerm,
    onClickTab,
    onAfterRemove,
    onSetDisplayType,
    onChangeSearchTerm,
    onKickManualRecordDialog
}: Props) => {
    const [tabs, setTabs] = useState(getTabs(dataSet, id, activeTab));

    useEffect(() => {
        setTabs(getTabs(dataSet, id, activeTab));
    }, [dataSet, id, activeTab]);

    return (
        <Tabbar tabs={tabs} onClick={onClickTab} className={className}>
            {activeTab === 'feed' && hasContent && (
                <Toolbar inline>
                    <ToolbarGroup>
                        <TabbarSearch
                            searchTerm={searchTerm || ''}
                            forceSearchTerm={''}
                            searchPlaceholder={Translation.get('dataSet.toolbar.searchDataSet', 'feed-management')}
                            onMutation={onChangeSearchTerm}
                        />
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <DataSetManagerExport dataSet={dataSet} hasContent={hasContent} />
                    </ToolbarGroup>
                    {!hideGridView && (
                        <ToolbarGroup>
                            <ViewSwitch onSetDisplayType={onSetDisplayType} displayType={displayType} />
                        </ToolbarGroup>
                    )}
                    {dataSet?.customData?.isManual && (
                        <ToolbarGroup>
                            <Button variant="contained" color="primary" onClick={() => onKickManualRecordDialog(true)}>
                                {Translation.get('dataSet.toolbar.addRecord', 'feed-management')}
                            </Button>
                        </ToolbarGroup>
                    )}
                </Toolbar>
            )}
            {activeTab === 'outputs' && (
                <Toolbar inline>
                    <ToolbarGroup>
                        <OutputForm dataSet={dataSet}>
                            <Button endIcon={<Icon>add</Icon>}>{Translation.get('editor.createOutput', 'feed-management')}</Button>
                        </OutputForm>
                    </ToolbarGroup>
                </Toolbar>
            )}
            {activeTab === 'settings' && (
                <Toolbar inline>
                    <ToolbarGroup>
                        <FeedManagerDataSetRemoveForm dataSet={dataSet} callback={onAfterRemove}>
                            <Button endIcon={<Icon>delete</Icon>} color="error">
                                {Translation.get('editor.removeDataSet', 'feed-management')}
                            </Button>
                        </FeedManagerDataSetRemoveForm>
                    </ToolbarGroup>
                </Toolbar>
            )}
        </Tabbar>
    );
};
export default withComponentStore(DataSetManagerTabbar, 'FeedManager', []);
