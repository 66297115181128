/* eslint-disable react/display-name */
import React, { useState, forwardRef, useRef } from 'react';
import MUITableRow from '@mui/material/TableRow';
import Icon from 'components/ui-components-v2/Icon';
import TableCell from '@mui/material/TableCell';
import Tooltip from 'components/ui-components-v2/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ItemPreviewDialog } from 'components/feed-management/ItemPreview';
import DataItemKeyDisplay from 'components/feed-management/DataItemKeyDisplay';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import TableRowMutation from './table-row-mutation';
import '../styles/table-row.scss';

/**
 * Get the right position for the row actions overlay.
 * @param {object} current
 * @returns {number}
 */
const getRight = (current) => {
    if (!current) return false;
    return document.documentElement.clientWidth - current.getBoundingClientRect().right;
};

/**
 * Get the top poistion of the current row.
 * @param {object} current
 * @returns {number}
 */
const getTop = (current) => {
    if (!current) return false;
    return current.getBoundingClientRect().top + window.scrollY;
};

/**
 * Get the top poistion of the current row.
 * @param {object} current
 * @returns {number}
 */
const getHeight = (current) => {
    if (!current) return false;
    return current.getBoundingClientRect().height;
};

// Display a table row with the data for one feed item / record.
const TableRow = forwardRef(
    ({ structure = {}, handleEditItem, handleCopyItem, openConfirmDialog, listItem, dataSet, displayTypes, latestOperationId, showOperationsColumn }, ref) => {
        const [hovered, setHovered] = useState(false);
        const [detailOpen, setDetailOpen] = useState(false);
        const rowRef = useRef(null);

        /**
         * Do the Api call to invalidate (mark as updated) an item
         */
        const forceUpdate = () => {
            FeedRequest.get(`dataset/${dataSet._id}/item/${listItem._id}/invalidate`)
                .then(() => SnackbarUtils.success(Translation.get('dataSet.table.feedback.invalidate', 'feed-management')))
                .catch((error) => {
                    SnackbarUtils.error(error.response.data.message);
                });
        };

        /**
         * Do the API call to republish an item (optional immediatly)
         * @param {boolean} immediately
         */
        const forcePublish = (immediately) => {
            FeedRequest.get(`dataset/${dataSet._id}/item/${listItem._id}/republish`, { params: { immediately } })
                .then(() =>
                    SnackbarUtils.success(
                        Translation.get(immediately ? 'dataSet.table.feedback.publishNow' : 'dataSet.table.feedback.publish', 'feed-management')
                    )
                )
                .catch((error) => SnackbarUtils.error(error.response.data.message));
        };

        /**
         * Set the overing state for a row.
         * When hovered the button for the detail view is shown.
         * @param {object} listItem
         */
        const hoverRow = (listItem) => {
            setHovered(listItem._id);
        };

        return (
            <React.Fragment>
                <MUITableRow ref={rowRef} className="data-set-manager-row" onMouseEnter={() => hoverRow(listItem)} onMouseLeave={() => setHovered(false)}>
                    {showOperationsColumn && (
                        <TableCell className="data-set-manager-row__cell">
                            <TableRowMutation mutations={listItem.mutations} latestOperationId={latestOperationId} />
                        </TableCell>
                    )}
                    {structure
                        .filter((structureItem) => !structureItem.hideColumn)
                        .map((structureItem, indexStructure) => (
                            <TableCell className="data-set-manager-row__cell" key={indexStructure}>
                                <DataItemKeyDisplay type={structureItem.type} value={FeedHelpers.checkDotNotation(listItem.data, structureItem['key'])} />

                                {indexStructure === 0 && hovered && (
                                    <div
                                        className="data-set-manager-row__actions"
                                        style={{
                                            right: getRight(ref.current),
                                            top: getTop(rowRef.current),
                                            height: getHeight(rowRef.current)
                                        }}>
                                        <div className="data-set-manager-row__actions__wrapper">
                                            {(!dataSet.customData || !dataSet.customData.isManual) && (
                                                <Tooltip title={Translation.get('dataSet.table.invalidate', 'feed-management')}>
                                                    <IconButton onClick={() => forceUpdate()} className="data-set-manager-row__actions__btn">
                                                        <Icon>refresh</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {dataSet.autoPublish && (
                                                <React.Fragment>
                                                    <Tooltip title={Translation.get('dataSet.table.publishNow', 'feed-management')}>
                                                        <IconButton onClick={() => forcePublish(true)} className="data-set-manager-row__actions__btn">
                                                            <Icon>rocket</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={Translation.get('dataSet.table.publish', 'feed-management')}>
                                                        <IconButton onClick={() => forcePublish(false)} className="data-set-manager-row__actions__btn">
                                                            <Icon>publish</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>
                                            )}
                                            <Tooltip title={Translation.get('dataSet.table.viewDetails', 'feed-management')}>
                                                <IconButton onClick={() => setDetailOpen(true)} className="data-set-manager-row__actions__btn">
                                                    <Icon>visibility</Icon>
                                                </IconButton>
                                            </Tooltip>
                                            {dataSet.customData && dataSet.customData.isManual && (
                                                <React.Fragment>
                                                    <Tooltip title={Translation.get('dataSet.table.editItem', 'feed-management')}>
                                                        <IconButton
                                                            onClick={() => handleEditItem({ ...listItem })}
                                                            className="data-set-manager-row__actions__btn">
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={Translation.get('dataSet.table.copyItem', 'feed-management')}>
                                                        <IconButton
                                                            onClick={() => handleCopyItem({ ...listItem })}
                                                            className="data-set-manager-row__actions__btn">
                                                            <Icon>content_copy</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={Translation.get('dataSet.table.removeItem', 'feed-management')}>
                                                        <IconButton onClick={() => openConfirmDialog(listItem)} className="data-set-manager-row__actions__btn">
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </TableCell>
                        ))}
                </MUITableRow>
                {detailOpen && <ItemPreviewDialog item={listItem} displayTypes={displayTypes} showDialog={true} onClose={() => setDetailOpen(false)} />}
            </React.Fragment>
        );
    }
);

export default TableRow;
