import '../styles/main.scss';
import React, { Component } from 'react';
import DataHelpers from 'components/data/DataHelpers';
import EditorData from 'components/editor-data/EditorData';
import SocialCustomizerReview from './review';
import SocialCustomizerResult from './result';

/**
 * Social customizer
 * This is the component for the social customizer. This is a solution now used for VW.
 * To be updated in the future to a more generic solution.
 */
class SocialCustomizer extends Component {
    static defaultProps = {};

    constructor(props) {
        super(props);

        // Set initial state
        this.state = {
            dataSet: DataHelpers.clone(EditorData.getValueFromModel(props.data.social.dataModel)),
            list: DataHelpers.clone(EditorData.getValueFromModel(props.data.social.model))
        };
    }

    /**
     * Render
     */
    render() {
        const { dataSet, list } = this.state;
        const { data } = this.props;

        if (data.subType === 'result') {
            return (
                <div className="social-customizer">
                    <SocialCustomizerResult dataSet={dataSet} dataModel={data.social.dataModel} list={list} value={this.props.value} />
                </div>
            );
        } else {
            return (
                <div className="social-customizer">
                    <SocialCustomizerReview dataSet={dataSet} dataModel={data.social.dataModel} list={list} value={this.props.value} />
                </div>
            );
        }
    }
}

export default SocialCustomizer;
